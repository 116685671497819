import { format } from 'date-fns'
import React from 'react'
import { colors } from '../../../ui/colors.ts'
import { useAppSelector } from '../../../lib/redux/hook.ts'

interface Props {
    message: string
    date: Date
    sent: boolean
}
export function MessageComponent(props: Props) {
    const { message } = props
    const { date } = props
    const { sent } = props

    const layoutState = useAppSelector(state => state.layout)

    return (
        <div
            style={{
                padding: '8px 12px 8px 12px',
                backgroundColor: (sent ? layoutState.theme[500] : colors.white),
                borderRadius: '12px',
                gap: 8,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                width: '50%'
            }}
        >
            {/* {
                !sent && isBroadcast && !sameSanderAsPrevious &&
                <div className='text text--lg typography--semibold typography-neutral--400 pe-5'>
                    {loadSenderData(sender)}
                </div>
            } */}
            <div
                style={{
                    gap: 8,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    width: '100%'
                }}
            >
                <span className='text-text-lg font-medium text-white'>{message}</span>
                <span className='text-text-md font-normal text-white'>{format(new Date(date), 'HH:mm')}</span>
            </div>
        </div>
    )
}