import React, { ReactElement, useEffect } from "react";
import { keycloak } from "../../../lib/keycloak/index.ts";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hook.ts";
import { colors } from "../../../ui/colors.ts";
import { OperatorIcon } from "../../../ui/icons/operatorIcon.tsx";
import { LayoutComponent } from "../../../ui/layout/index.tsx";
import { ButtonComponent } from "../../../ui/molecules/button/index.tsx";
import { ErrorPopup } from "../../../ui/organisms/errorPopup/index.tsx";
import { HeadingSize } from "../../../ui/organisms/heading/dto.ts";
import { HeadingComponent } from "../../../ui/organisms/heading/index.tsx";
import { Pagination } from "../../../ui/organisms/pagination/index.tsx";
import { MenuItems } from "../../../ui/organisms/sidebar/dto.ts";
import { SuccessPopup } from "../../../ui/organisms/successPopup/index.tsx";
import { CollaboratorFilters } from "./collaboratorFilters.tsx";
import { CollaboratorList } from "./list.tsx";
import { NewCollaboratorModal } from "./newCollaboratorModal.tsx";
import { findAllCollaborator, setCollaboratorFilterPage, setCreateCollaboratorsStatus, setDeleteCollaboratorStatus, setEditCollaboratorStatus, setOpenNewCollaboratorModal, setSaveOrEdit } from "./slice.ts";

export function Collaborator() {
    const dispatch = useAppDispatch()
    const collaboratorState = useAppSelector(state => state.collaborator)
    const layoutState = useAppSelector(state => state.layout)

    useEffect(() => {
        dispatch(findAllCollaborator(collaboratorState.collaboratorFilters))
    }, [collaboratorState.collaboratorFilters.page])

    let body: ReactElement[] = []

    body.push(
        <>
            <SuccessPopup
                active={collaboratorState.createCollaboratorStatus === "successfully"}
                close={() => dispatch(setCreateCollaboratorsStatus("idle"))}
                message="Collaboratore aggiunto"
            />
            <ErrorPopup
                active={collaboratorState.createCollaboratorStatus === "failed"}
                close={() => dispatch(setCreateCollaboratorsStatus("idle"))}
                message="Errore durante la creazione del collaboratore."
            />
            <SuccessPopup
                active={collaboratorState.editCollaboratorStatus === "successfully"}
                close={() => dispatch(setEditCollaboratorStatus("idle"))}
                message="Collaboratore modificato"
            />
            <ErrorPopup
                active={collaboratorState.editCollaboratorStatus === "failed"}
                close={() => dispatch(setEditCollaboratorStatus("idle"))}
                message="Errore durante la modifica del collaboratore."
            />
            <SuccessPopup
                active={collaboratorState.deleteCollaboratorStatus === "successfully"}
                close={() => dispatch(setDeleteCollaboratorStatus("idle"))}
                message="Collaboratore eliminato"
            />
            <ErrorPopup
                active={collaboratorState.deleteCollaboratorStatus === "failed"}
                close={() => dispatch(setDeleteCollaboratorStatus("idle"))}
                message="Errore durante l'eliminazione del collaboratore."
            />
            <CollaboratorFilters />
            <CollaboratorList />
            {
                collaboratorState.findAllCollaboratorResponse !== undefined &&
                collaboratorState.findAllCollaboratorResponse?.total > 0 &&
                <div className="flex bg-white drop-shadow-sm items-center justify-center p-2">
                    <Pagination
                        pages={collaboratorState.findAllCollaboratorResponse?.totalPage || 1}
                        currentPage={collaboratorState.collaboratorFilters.page}
                        setPage={(page) => dispatch(setCollaboratorFilterPage(page))}
                    />
                </div>
            }
            <NewCollaboratorModal />
        </>
    )

    return (
        <>
            {
                keycloak.hasRealmRole("financial_advisor") ?
                    <LayoutComponent
                        breadcrumbItems={['Collaboratori']}
                        headingLabel={"Collaboratori"}
                        headingButtons={[
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', gap: 16 }}>
                                <ButtonComponent
                                    label={"Nuovo collaboratore"}
                                    onClick={() => {
                                        dispatch(setSaveOrEdit('save'))
                                        dispatch(setOpenNewCollaboratorModal(true))
                                    }}
                                    color={"blue"}
                                    variant={"solid"}
                                    size={"md"}
                                    iconPosition="off"
                                />
                            </div>
                        ]}
                        menuItem={MenuItems.COLLABORATOR}
                        breadcrumbIcon={<OperatorIcon color={layoutState.theme[500]} size={"20"} />}
                    >
                        {body}
                    </LayoutComponent>
                    :
                    <div className={"flex flex-col gap-3 flex-grow"}>
                        <HeadingComponent
                            label={"Collaboratori"}
                            buttons={[
                                <ButtonComponent
                                    label={"Aggiungi collaboratore"}
                                    onClick={() => {
                                        dispatch(setSaveOrEdit('save'))
                                        dispatch(setOpenNewCollaboratorModal(true))
                                    }}
                                    color={"blue"}
                                    variant={"outline"}
                                    size={"md"}
                                    iconPosition="off"
                                />
                            ]}
                            size={HeadingSize.LG}
                        />
                        {body}
                    </div>
            }
        </>
    )
}