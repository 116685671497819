import React, { useEffect } from 'react';
import { keycloak } from './app/lib/keycloak/index.ts';
import { setKeycloakInitStatus, setRoles, setSocket, urlMap } from './app/lib/keycloak/slice.ts';
import { findAvatarByObjectId, setFindAvatarbyObjectIdStatus } from './app/lib/object/slice.ts';
import { useAppDispatch, useAppSelector } from './app/lib/redux/hook.ts';
import { KeycloakRouter } from './app/lib/router/index.tsx';
import { generateTheme } from './app/lib/utilis/index.ts';
import { findMeCollaborator, findMeCompany, findMeFinancialAdvisor, findMeOperator, findMeReferrer } from './app/pages/auth/slice.ts';
import { setDocumentFilterCompanyId } from './app/pages/company/documents/slice.ts';
import { setEventFilterCompanyId, setNewEventCompanyId } from './app/pages/company/events/slice.ts';
import { findByIdCompany } from './app/pages/company/slice.ts';
import { findByIdFinancialAdvisor } from './app/pages/financialAdvisor/slice.ts';
import { NoticeRead } from './app/pages/notice/dto.ts';
import { setNoticeFilterReceiver, setNoticeFiltersRead } from './app/pages/notice/slice.ts';
import { setTheme } from './app/ui/layout/slice.ts';
import { SpinnerComponent } from './app/ui/molecules/spinner/index.tsx';

function App() {
  const dispatch = useAppDispatch()

  const keycloakState = useAppSelector(state => state.secured)
  const authState = useAppSelector(state => state.auth)
  const companyState = useAppSelector(state => state.company)
  const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
  const objectState = useAppSelector(state => state.object)

  useEffect(() => {
    if ((authState.findMeCompanyStatus === "successfully" && keycloak.hasRealmRole("company")) ||
      authState.findMeReferrersStatus === "successfully" && keycloak.hasRealmRole("administrative_company")) {
      if (keycloak.hasRealmRole("administrative_company"))
        dispatch(findByIdCompany(authState.findMeReferrersResponse?.company!))
      dispatch(setDocumentFilterCompanyId(authState.findMeReferrersResponse?.id ?? authState.findMeCompanyResponse?.id))
      dispatch(setEventFilterCompanyId(authState.findMeReferrersResponse?.id ?? authState.findMeCompanyResponse?.id))
      dispatch(setNewEventCompanyId(authState.findMeReferrersResponse?.id ?? authState.findMeCompanyResponse?.id))
      dispatch(setNoticeFilterReceiver('true'))
      dispatch(setNoticeFiltersRead(NoticeRead.toRead))
    }
  }, [authState.findMeCompanyStatus, authState.findMeReferrersStatus, keycloak.hasRealmRole])

  useEffect(() => {
    if (authState.findMeCompanyStatus === "successfully" || (authState.findMeReferrersStatus === "successfully" && companyState.findByIdCompanyStatus === "successfully")) {
      let financialAdvisorId = authState.findMeCompanyResponse?.financialAdvisor ?? companyState.findByIdCompanyResponse?.id
      dispatch(findByIdFinancialAdvisor(financialAdvisorId!))
    }
  }, [authState.findMeCompanyStatus, authState.findMeReferrersStatus, companyState.findByIdCompanyStatus])

  useEffect(() => {
    if (financialAdvisorState.findByIdFinancialAdvisorStatus === "successfully") {
      dispatch(findAvatarByObjectId(financialAdvisorState.findByIdFinancialAdvisorResponse?.avatarObjectId!))
    }
  }, [financialAdvisorState.findByIdFinancialAdvisorStatus])

  useEffect(() => {
    if (objectState.findAvatarByObjectIdStatus === "successfully" && !authState.avatar) {
      dispatch(setFindAvatarbyObjectIdStatus("idle"))
    }
  }, [objectState.findAvatarByObjectIdStatus, authState.avatar])

  keycloak.onAuthSuccess = () => {
    dispatch(setRoles(keycloak.tokenParsed?.realm_access?.roles))
    dispatch(setSocket(new WebSocket(urlMap.get(window.location.origin) + "/chat", ["access_token", keycloak.token || ''])))
    dispatch(setKeycloakInitStatus('successfully'))
  }

  useEffect(() => {
    switch (true) {
      case keycloakState.userRoles.includes("financial_advisor"):
        dispatch(findMeFinancialAdvisor());
        break;
      case keycloakState.userRoles.includes("operator"):
        dispatch(findMeOperator());
        break;
      case keycloakState.userRoles.includes("financial_advisor_collaborator"):
        dispatch(findMeCollaborator());
        break;
      case keycloakState.userRoles.includes("administrative_company"):
        dispatch(findMeReferrer());
        break;
      case keycloakState.userRoles.includes("company"):
        dispatch(findMeCompany());
        break;
      default:
        break;
    }
  }, [keycloakState.userRoles]);

  useEffect(() => {
    if ((authState.findMeCompanyStatus === "successfully" && authState.findMeCompanyResponse?.primaryColor !== null) ||
      (authState.findMeCollaboratorStatus === "successfully" && authState.findMeCollaboratorResponse?.primaryColor !== null) ||
      (authState.findMeReferrersStatus === "successfully" && authState.findMeReferrersResponse?.primaryColor !== null) ||
      (authState.findMeFinancialAdvisorStatus === "successfully" && authState.findMeFinancialAdvisorResponse?.primaryColor !== null)) {
      const primaryColor = authState.findMeCompanyResponse?.primaryColor ?? authState.findMeCollaboratorResponse?.primaryColor ?? authState.findMeFinancialAdvisorResponse?.primaryColor ?? authState.findMeReferrersResponse?.primaryColor;
      const theme = generateTheme(primaryColor!);

      dispatch(setTheme(theme));
    }
  }, [authState.findMeCompanyStatus, authState.findMeCollaboratorStatus, authState.findMeReferrersStatus, authState.findMeFinancialAdvisorStatus]);

  keycloak.onAuthRefreshError = () => {
    keycloak.logout()
  }

  keycloak.onAuthRefreshSuccess = () => {
    keycloakState.socket?.close()
    dispatch(setSocket(new WebSocket(urlMap.get(window.location.origin) + "/chat", ["access_token", keycloak.token || ''])))
  }

  if (
    keycloakState.keycloakInitStatus === 'idle' ||
    authState.findMeCollaboratorStatus === "loading" ||
    authState.findMeCompanyStatus === "loading" ||
    authState.findMeFinancialAdvisorStatus === "loading" ||
    authState.findMeOperatorStatus === "loading" ||
    authState.findMeReferrersStatus === "loading"
  ) {
    return (
      <div className={"w-full h-[100vh] flex justify-center items-center"}>
        <SpinnerComponent />
      </div>
    )
  }

  return (
    <div className="App">
      <KeycloakRouter />
    </div>
  );
}

export default App;
