import React, { ReactNode } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hook.ts";
import { HeadingSize } from "../../../ui/organisms/heading/dto.ts";
import { HeadingComponent } from "../../../ui/organisms/heading/index.tsx";

export function BankStatements() {
    const dispatch = useAppDispatch()
    const bankState = useAppSelector(state => state.bank)

    let acubeBankStatements: ReactNode[] = []

    if (
        bankState.findByIdAcubeGetAccountStatus === 'successfully' &&
        bankState.findByIdAcubeGetAccountResponse !== undefined &&
        bankState.findByIdAcubeGetAccountResponse !== null &&
        bankState.findByIdAcubeGetAccountResponse.length > 0
    ) {
        bankState.findByIdAcubeGetAccountResponse.forEach((bank, index) => {
            for (let i = 0; i < 8; i++) {
                const year = i < 4 ? 2024 : 2023;
                const quarter = `Q${(i % 4) + 1}`;
                acubeBankStatements.push(
                    <tr
                        className="bg-white h-[56px] border-b-[1px] border-b-neutral-100"
                        key={`${bank.uuid}-${bank.name}-${i}`}
                    >
                        <td className="px-2 py-4 text-text-md font-semibold text-neutral-850">
                            {`${bank.name} - Element ${i + 1} (${quarter})`}
                        </td>
                        <td className="px-2 py-4 text-text-md text-neutral-600">
                            {bank.iban === null ? '-' : `${bank.iban}-${i + 1}`}
                        </td>
                        <td className="px-2 py-4 text-text-md text-neutral-600">
                            {year}
                        </td>
                    </tr>
                );
            }
        });
    }

    return (
        <div className="pt-8 flex flex-col gap-3 flex-grow">
            <HeadingComponent
                label={"Ultimi estratti conto"}
                buttons={[]}
                size={HeadingSize.SM}
            />
            <div className="w-full flex flex-col gap-4 rounded-tr-xl rounded-tl-xl overflow-auto">
                <div>
                    <table className="w-full mb-3">
                        <thead className="h-[56px] bg-neutral-100 font-normal text-neutral-500 text-text-xs">
                            <th className="text-left">
                                <div className="flex py-4 px-2 w-[200px] gap-1">
                                    NOME
                                </div>
                            </th>
                            <th className="text-left">
                                <div className="flex py-4 px-2 w-[280px] gap-1">
                                    IBAN
                                </div>
                            </th>
                            <th className="text-left">
                                <div className="flex py-4 px-2 w-[280px] gap-1">
                                    NOME FILE
                                </div>
                            </th>
                            <th className="text-center">
                                <div className="flex py-4 px-2 w-[160px] gap-1">
                                    ANNO
                                </div>
                            </th>
                            <th className="text-center">
                                <div className="flex py-4 px-2 w-[160px] gap-1">
                                    TRIMESTRE
                                </div>
                            </th>
                            <th className="text-left">
                                <div className="flex py-4 px-2 2-[40px] gap-1" />
                            </th>
                            <th className="text-left">
                                <div className="flex py-4 px-2 2-[40px] gap-1" />
                            </th>
                        </thead>
                        <tbody>
                            {acubeBankStatements}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}