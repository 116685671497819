export interface FinancialAdvisorDTO {
    id: string
    name: string
    surname: string
    email: string
    userId: string
    avatarObjectId: string
    phoneNumber: string
    commercialName: string
    subscriptionType: string
    subscriptionExpirationDate: Date
    collaborators: string[]
    companies: string[]
    note: string
    subscriptionTerm: number
    renewalDate: Date
    maxNumberOfCompanies: number
    freeSlotOfCompanies: number
    primaryColor: string
}

export interface findAllFinancialAdvisorResponseDTO {
    total: number
    page: number
    perPage: number
    prev: boolean
    next: boolean
    totalPage: number
    data: FinancialAdvisorDTO[]
}

export interface FindAllFinancialAdvisorFiltersDTO {
    itemsPerPage: number
    order: boolean
    page: number
    sort: string
    name: string
}

export interface CreateFinancialAdvisorDTO {
    name: string
    surname: string
    email: string
    phoneNumber: string
    commercialName: string
    subscriptionType: SubscriptionType,
    subscriptionExpirationDate: Date
    avatarObjectId: number
    userId: string,
    note: string
    avatar: File | null
    subscriptionTerm: number
    freeSlotOfCompanies: number
    maxNumberOfCompanies: number
    maxNumberofCompaniesType: NumberofCompaniesType
    primaryColor?: string
}

export interface EditFinancialAdvisorDTO {
    name: string
    surname: string
    email: string
    phoneNumber: string
    commercialName: string
    avatarObjectId: number
    note: string
    avatar: File | null
    primaryColor: string
}

export enum SubscriptionType {
    trial = "trial",
    active = "active",
    expired = "expired",
    canceled = "canceled"
}

export enum NumberofCompaniesType {
    trial = "trial",
    base = "base",
    ultimate = "ultimate",
    pro = "pro"
}