import React, { useEffect } from "react"
import { keycloak } from "../../../lib/keycloak/index.ts"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hook.ts"
import { ChatIcon } from "../../../ui/icons/chatIcon.tsx"
import { LayoutComponent } from "../../../ui/layout/index.tsx"
import { MenuItems } from "../../../ui/organisms/sidebar/dto.ts"
import { ChatComponent } from "./chat.tsx"
import { findChats } from "./slice.ts"
import { UsersList } from "./usersList.tsx"

export function Chat() {
    const companyState = useAppSelector(state => state.company)
    const layoutState = useAppSelector(state => state.layout)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (companyState.findByIdCompanyStatus === 'successfully' && companyState.findByIdCompanyResponse !== undefined) {
            dispatch(findChats(companyState.findByIdCompanyResponse.id))
        }
    }, [companyState.findByIdCompanyStatus])

    return (
        <>
            {
                keycloak.hasRealmRole("company") || keycloak.hasRealmRole("administrative_company") ?
                    <LayoutComponent
                        menuItem={MenuItems.CHAT}
                        headingLabel={"Messaggi"}
                        headingButtons={[]}
                        breadcrumbItems={["Messaggi"]}
                        breadcrumbIcon={<ChatIcon size={'20'} color={layoutState.theme[500]} />}
                    >
                        <div className="w-full flex flex-row gap-4" style={{ height: 'calc(100vh - 250px)' }}>
                            <UsersList />
                            <ChatComponent />
                        </div>
                    </LayoutComponent>
                    :
                    <div className="w-full flex flex-row gap-4" style={{ height: 'calc(100vh - 250px)' }}>
                        <UsersList />
                        <ChatComponent />
                    </div>
            }
        </>
    )
}