import { format, isValid } from "date-fns"
import React, { useEffect } from "react"
import { keycloak } from "../../../lib/keycloak/index.ts"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hook.ts"
import { colors } from "../../../ui/colors.ts"
import { ButtonComponent } from "../../../ui/molecules/button/index.tsx"
import { FileInputComponent } from "../../../ui/molecules/fileInput/index.tsx"
import { InputComponent } from "../../../ui/molecules/input/index.tsx"
import { ModalComponent } from "../../../ui/organisms/modal/index.tsx"
import { createEvent, editEvent, resetEditEventRequest, resetNewEventRequest, setEditEventDate, setEditEventName, setEditEventNote, setNewEventCompanyId, setNewEventDate, setNewEventName, setNewEventNote, setOpenNewEventsModal } from "./slice.ts"

export function NewEventModal() {
    const eventsState = useAppSelector(state => state.events)
    const companyState = useAppSelector(state => state.company)
    const layoutState = useAppSelector(state => state.layout)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (eventsState.openNewEventsModal && eventsState.modalEventType === 'save' && !keycloak.hasRealmRole("company") && !keycloak.hasRealmRole("administrative_company")) {
            dispatch(setNewEventCompanyId(companyState.findByIdCompanyResponse?.id))
        }
    }, [eventsState.openNewEventsModal, eventsState.modalEventType, keycloak.hasRealmRole])

    return (
        <ModalComponent
            width="medium"
            open={eventsState.openNewEventsModal}
            handleClose={() => {
                dispatch(setOpenNewEventsModal(false))
                dispatch(resetNewEventRequest())
                dispatch(resetEditEventRequest())
            }}
        >
            <div
                style={{
                    boxShadow: '0px 12px 24px 0px rgba(22, 42, 100, 0.12)',
                }}
                className={"p-5 bg-white rounded-xl flex flex-col gap-6 w-full"}
            >
                <div className="flex items-center justify-between">
                    <span className="text-heading-xs font-semibold text-neutral-800">
                        {eventsState.modalEventType === 'save' ? 'Aggiungi' : 'Modifica'}
                    </span>
                </div>
                <div className="flex flex-col items-start" style={{ gap: '16px', width: '100%' }}>
                    <FileInputComponent
                        colorIcon={layoutState.theme[500]}
                        id={"create-financial-advisor-avatar"}
                        onChange={undefined}
                        iconSize={"32"}
                        color="blue"
                        iconBackground={layoutState.theme[100]}
                    />
                    <InputComponent
                        key={"new-event-modal-input-name"}
                        label="Nome evento"
                        placeholder={"Nome evento"}
                        type="text"
                        defaultValue={eventsState.modalEventType === 'save' ? eventsState.eventCreationRequest.name : eventsState.editEventRequest.name}
                        onChangeText={e => {
                            if (eventsState.modalEventType === 'save')
                                dispatch(setNewEventName(e))
                            else {
                                dispatch(setEditEventName(e))
                            }
                        }}
                        required
                    />
                    <InputComponent
                        label="Scadenza evento"
                        placeholder={"Seleziona data"}
                        type="date"
                        key={"new-event-modal-input-expiration"}
                        defaultValue={eventsState.modalEventType === 'save' ? eventsState.eventCreationRequest.date : eventsState.editEventRequest.date}
                        onChangeText={(e) => {
                            if (isValid(new Date(e)))
                                if (eventsState.modalEventType === 'save')
                                    dispatch(setNewEventDate(format(new Date(e), 'yyyy-MM-dd')))
                                else {
                                    dispatch(setEditEventDate(format(new Date(e), 'yyyy-MM-dd')))
                                }
                            else {
                                if (eventsState.modalEventType === 'save')
                                    dispatch(setNewEventDate(format(new Date(), 'yyyy-MM-dd')))
                                else {
                                    dispatch(setEditEventDate(format(new Date(), 'yyyy-MM-dd')))
                                }
                            }
                        }}
                        required
                    />
                    <InputComponent
                        label="Note"
                        placeholder="Informazioni sull'evento"
                        defaultValue={eventsState.modalEventType === 'save' ? eventsState.eventCreationRequest.note : eventsState.editEventRequest.note}
                        supportingText="275 caratteri rimasti"
                        multiple
                        type={"text"}
                        onChangeText={e => {
                            if (eventsState.modalEventType === 'save')
                                dispatch(setNewEventNote(e))
                            else {
                                dispatch(setEditEventNote(e))
                            }
                        }}
                    />
                </div>
                <div className="flex gap-3 items-center">
                    <ButtonComponent
                        label={"Annulla"}
                        onClick={() => {
                            dispatch(setOpenNewEventsModal(false))
                            dispatch(resetNewEventRequest())
                            dispatch(resetEditEventRequest())
                        }}
                        color={"gray"}
                        variant={"outline"}
                        size={"sm"}
                        iconPosition="off"
                        fullWidth
                    />
                    <ButtonComponent
                        label={eventsState.modalEventType === 'save' ? "Aggiungi Evento" : 'Modifica Evento'}
                        onClick={() => {
                            if (eventsState.modalEventType === 'save') {
                                dispatch(createEvent(eventsState.eventCreationRequest))
                                dispatch(setOpenNewEventsModal(false))
                                dispatch(resetNewEventRequest())
                            } else {
                                dispatch(editEvent({ id: eventsState.editEventId.toString(), request: eventsState.editEventRequest }))
                                dispatch(setOpenNewEventsModal(false))
                                dispatch(resetEditEventRequest())
                            }
                        }}
                        color={"blue"}
                        variant={"solid"}
                        size={"sm"}
                        iconPosition="off"
                        disabled={
                            eventsState.modalEventType === 'save' && (
                                eventsState.eventCreationRequest.name === undefined ||
                                eventsState.eventCreationRequest.date === undefined
                            ) ||
                            eventsState.modalEventType === 'edit' && (
                                eventsState.editEventRequest.name === undefined ||
                                eventsState.editEventRequest.date === undefined
                            )
                        }
                        fullWidth
                    />
                </div>
            </div>
        </ModalComponent >
    )
}