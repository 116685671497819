import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { PromiseStatuses } from "../utilis/index.ts"
import { NewObjectService } from "./service.ts"

interface ObjectState {
    document: string | null
    findDocumentStatus: PromiseStatuses
    findAvatarByObjectIdStatus: PromiseStatuses
    avatarResponse: string | null
}

const initialState: ObjectState = {
    document: null,
    findDocumentStatus: 'idle',
    findAvatarByObjectIdStatus: 'idle',
    avatarResponse: null
}

export const findFileById = createAsyncThunk(
    'objects/findFileById',
    async (objectId: string, thunkApi): Promise<string> => {
        const objectService = NewObjectService()

        return objectService.findFileById(objectId).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const findAvatarByObjectId = createAsyncThunk(
    'objects/findAvatarByObjectId',
    async (objectId: string, thunkApi): Promise<string> => {
        const objectService = NewObjectService()

        return objectService.findFileById(objectId).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

const ObjectSlice = createSlice({
    name: 'object/slice',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(findFileById.pending, (state) => {
                state.findDocumentStatus = 'loading'
            })
            .addCase(findFileById.fulfilled, (state, action) => {
                state.findDocumentStatus = 'successfully'
                state.document = action.payload
            })
            .addCase(findFileById.rejected, (state) => {
                state.findDocumentStatus = 'failed'
            })
            .addCase(findAvatarByObjectId.pending, (state) => {
                state.findAvatarByObjectIdStatus = 'loading'
            })
            .addCase(findAvatarByObjectId.fulfilled, (state, action) => {
                state.findAvatarByObjectIdStatus = 'successfully'
                state.avatarResponse = action.payload
            })
            .addCase(findAvatarByObjectId.rejected, (state) => {
                state.findAvatarByObjectIdStatus = 'failed'
            })
    },
    reducers: {
        setFindDocumentStatus: (state, action) => {
            state.findDocumentStatus = action.payload
        },
        setFindAvatarbyObjectIdStatus: (state, action) => {
            state.findAvatarByObjectIdStatus = action.payload
        }
    },
})

export const {
    setFindDocumentStatus,
    setFindAvatarbyObjectIdStatus
} = ObjectSlice.actions

export default ObjectSlice.reducer
