
import React, { useEffect, useState } from "react";
import { keycloak } from "../../lib/keycloak/index.ts";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hook.ts";
import { toBase64 } from "../../lib/utilis/index.ts";
import { colors } from "../../ui/colors.ts";
import { EmailIcon } from "../../ui/icons/emailIcon.tsx";
import Avatar from "../../ui/molecules/avatar/index.tsx";
import { ButtonComponent } from "../../ui/molecules/button/index.tsx";
import { FileInputComponent } from "../../ui/molecules/fileInput/index.tsx";
import { InputComponent } from "../../ui/molecules/input/index.tsx";
import { SelectComponent } from "../../ui/molecules/select/index.tsx";
import { ModalComponent } from "../../ui/organisms/modal/index.tsx";
import { createCompany, resetCreateCompanyRequest, setCreateCompanyCompanyName, setCreateCompanyEmail, setCreateCompanyFinancialAdvisorId, setCreateCompanyName, setCreateCompanyNote, setCreateCompanySurname, setCreateVat, setOpenNewCompanyModal } from "./slice.ts";

export function NewCompanyModal() {
    const companyState = useAppSelector(state => state.company)
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const dispatch = useAppDispatch()

    const [file, setFile] = useState<File | null>(null)
    const [avatarToBase64, setAvatarToBase64] = useState<string>('')

    let formData = new FormData();

    useEffect(() => {
        if (companyState.openNewCompanyModal) {
            setFile(null)
            setAvatarToBase64('')
        }
    }, [companyState.openNewCompanyModal])

    return (
        <ModalComponent
            width="medium"
            open={companyState.openNewCompanyModal}
            handleClose={() => {
                dispatch(setOpenNewCompanyModal(false));
            }}
        >
            <div className={"p-5 bg-white rounded-xl flex flex-col gap-5 w-full"}
                style={{
                    boxShadow: '0px 12px 24px 0px rgba(22, 42, 100, 0.12)',
                }}
            >
                <div className="flex items-center justify-between">
                    <span className="text-heading-xs font-semibold text-neutral-600">Aggiungi azienda</span>
                </div>
                {
                    keycloak.hasRealmRole('financial_advisor') &&
                    <div className="flex items-center bg-neutral-300 rounded-3xl flex-row p-3 gap-1">
                        <span className="text-text-md font-semibold text-neutral-800">Puoi aggiungere ancora</span>
                        <span className="text-text-md font-semibold text-brandPrimary-500">10 aziende</span>
                    </div>
                }
                <div className="flex flex-col items-start" style={{ gap: '16px', width: '100%' }}>
                    <div className="flex gap-[16px] flex-row w-full">
                        <Avatar
                            type="single"
                            size="md"
                            shape="circle"
                            imageUrl={avatarToBase64}
                            altTextInitials={(companyState.createCompanyRequest.name[0] || 'A') + (companyState.createCompanyRequest.surname[0] || 'A')}
                        />
                        <FileInputComponent
                            id={"create-financial-advisor-avatar"}
                            onChange={(e) => {
                                setFile(e[0])
                                toBase64(e[0]).then(data => setAvatarToBase64('data:' + e[0].type + ';base64,' + data))
                            }}
                            color={"gray"}
                            iconSize="32"
                            iconBackground={colors.neutral[100]}
                            infoText={false}
                        />
                    </div>
                    <InputComponent
                        type={"text"}
                        placeholder={"Ragione sociale"}
                        defaultValue={companyState.createCompanyRequest.companyName}
                        onChangeText={(value) => dispatch(setCreateCompanyCompanyName(value))}
                        required
                    />
                    <InputComponent
                        type={"text"}
                        placeholder={"Partita iva"}
                        defaultValue={companyState.createCompanyRequest.vat}
                        onChangeText={(value) => dispatch(setCreateVat(value))}
                        required
                    />
                    <InputComponent
                        type={"text"}
                        defaultValue={companyState.createCompanyRequest.name}
                        placeholder={"Nome"}
                        onChangeText={(value) => dispatch(setCreateCompanyName(value))}
                        required
                    />
                    <InputComponent
                        type={"text"}
                        defaultValue={companyState.createCompanyRequest.surname}
                        placeholder={"Cognome"}
                        onChangeText={(value) => dispatch(setCreateCompanySurname(value))}
                        required
                    />
                    <InputComponent
                        type={"text"}
                        defaultValue={companyState.createCompanyRequest.email}
                        placeholder="Email"
                        startIcon={<EmailIcon size={"20"} color={colors.neutral[400]} />}
                        onChangeText={(value) => dispatch(setCreateCompanyEmail(value))}
                        required
                    />
                    {
                        !keycloak.hasRealmRole("financial_advisor") &&
                        <SelectComponent
                            placeholder={"Tutti i commercialisti"}
                            key={'new-company-by-financial-advisor'}
                            onChange={(value) => dispatch(setCreateCompanyFinancialAdvisorId(value))}
                            options={financialAdvisorState.findAllFinancialAdvisorResponse?.data.map((value) => ({ label: (value.name + ' ' + value.surname), value: value.id }))!}
                            value={companyState.createCompanyRequest.financialAdvisorId.toString()}
                        />
                    }
                    <InputComponent
                        type={"text"}
                        label="Descrizione"
                        placeholder="Un po' di informazioni su di te"
                        supportingText="275 caratteri rimasti"
                        multiple
                        onChangeText={(value) => dispatch(setCreateCompanyNote(value))}
                        defaultValue={companyState.createCompanyRequest.note}
                    />
                </div>
                <div className={"flex gap-3 items-center"}>
                    <ButtonComponent
                        label="Annulla"
                        size={"sm"}
                        iconPosition={"off"}
                        variant={"outline"}
                        color={"gray"}
                        onClick={() => {
                            dispatch(setOpenNewCompanyModal(false))
                            dispatch(resetCreateCompanyRequest())
                        }}
                        fullWidth
                    />
                    <ButtonComponent
                        label={"Avvia procedura"}
                        onClick={() => {
                            if (file !== null)
                                formData.append('avatar', file)
                            formData.append('companyCreationDTO', JSON.stringify(companyState.createCompanyRequest))
                            dispatch(createCompany(formData));
                            dispatch(setOpenNewCompanyModal(false));
                        }}
                        color={"blue"}
                        variant={"solid"}
                        size={"sm"}
                        iconPosition={"off"}
                        fullWidth
                        disabled={
                            companyState.createCompanyRequest.name === undefined ||
                            companyState.createCompanyRequest.surname === undefined ||
                            companyState.createCompanyRequest.email === undefined ||
                            companyState.createCompanyRequest.companyName === undefined ||
                            companyState.createCompanyRequest.vat === undefined ||
                            companyState.createCompanyRequest.financialAdvisorId === 0
                        }
                    />
                </div>
            </div>
        </ModalComponent>
    )
}