
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hook.ts";
import { toBase64 } from "../../lib/utilis/index.ts";
import { colors } from "../../ui/colors.ts";
import { EmailIcon } from "../../ui/icons/emailIcon.tsx";
import Avatar from "../../ui/molecules/avatar/index.tsx";
import { ButtonComponent } from "../../ui/molecules/button/index.tsx";
import { FileInputComponent } from "../../ui/molecules/fileInput/index.tsx";
import { InputComponent } from "../../ui/molecules/input/index.tsx";
import { ModalComponent } from "../../ui/organisms/modal/index.tsx";
import { createOperator, resetCreateOperatorRequest, setCreateOperatorRequestEmail, setCreateOperatorRequestName, setCreateOperatorRequestNote, setCreateOperatorRequestSurname, setOpenNewOperatorModal } from "./slice.ts";
export function NewOperatorModal() {
    const operatorState = useAppSelector(state => state.operator)
    const dispatch = useAppDispatch()

    const [file, setFile] = useState<File | null>(null)
    const [avatarToBase64, setAvatarToBase64] = useState<string>('')

    let formData = new FormData();

    useEffect(() => {
        if (operatorState.openNewOperatorModal) {
            setFile(null)
            setAvatarToBase64('')
        }
    }, [operatorState.openNewOperatorModal])

    return (
        <ModalComponent
            width="medium"
            open={operatorState.openNewOperatorModal}
            handleClose={() => {
                dispatch(setOpenNewOperatorModal(false));
            }}
        >
            <div
                style={{
                    padding: '20px',
                    backgroundColor: colors.white,
                    boxShadow: '0px 12px 24px 0px rgba(22, 42, 100, 0.12)',
                    borderRadius: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    width: '100%'
                }}
            >
                <div className="flex items-center justify-between">
                    <span className="text-heading-xs font-semibold text-neutral-600">Aggiungi operatore</span>
                </div>
                <div className="flex flex-col items-start" style={{ gap: '16px', width: '100%' }}>
                    <div className="flex flex-row w-full">
                        <Avatar
                            type="single"
                            size="md"
                            shape="circle"
                            imageUrl={avatarToBase64}
                            altTextInitials={(operatorState.createOperatorRequest.name[0] || 'A') + (operatorState.createOperatorRequest.surname[0] || 'A')}
                        />
                        <FileInputComponent
                            id={"create-operator-avatar"}
                            onChange={(e) => {
                                setFile(e[0])
                                toBase64(e[0]).then(data => setAvatarToBase64('data:' + e[0].type + ';base64,' + data))
                            }}
                            color={"gray"}
                            iconSize="32"
                            iconBackground={colors.neutral[100]}
                            infoText={false}
                        />
                    </div>
                    <InputComponent
                        type={"text"}
                        defaultValue={operatorState.createOperatorRequest.name}
                        placeholder={"Nome"}
                        onChangeText={(value) => dispatch(setCreateOperatorRequestName(value))}
                        required
                    />
                    <InputComponent
                        type={"text"}
                        defaultValue={operatorState.createOperatorRequest.surname}
                        placeholder={"Cognome"}
                        onChangeText={(value) => dispatch(setCreateOperatorRequestSurname(value))}
                        required
                    />
                    <InputComponent
                        type={"text"}
                        placeholder="Email"
                        startIcon={<EmailIcon size={"20"} color={colors.neutral[400]} />}
                        defaultValue={operatorState.createOperatorRequest.email}
                        onChangeText={(value) => dispatch(setCreateOperatorRequestEmail(value))}
                        required
                    />
                    <InputComponent
                        type={"text"}
                        label="Descrizione"
                        placeholder="Un po' di informazioni su di te"
                        supportingText="275 caratteri rimasti"
                        multiple
                        onChangeText={(value) => dispatch(setCreateOperatorRequestNote(value))}
                        defaultValue={operatorState.createOperatorRequest.note}
                    />
                </div>
                <div className={"flex gap-3 items-center"}>
                    <ButtonComponent
                        label={"Annulla"}
                        onClick={() => {
                            dispatch(setOpenNewOperatorModal(false))
                            dispatch(resetCreateOperatorRequest())
                        }}
                        color={"gray"}
                        variant={"outline"}
                        size={"sm"}
                        iconPosition="off"
                        fullWidth
                    />
                    <ButtonComponent
                        label={"Avvia procedura"}
                        onClick={() => {
                            if (file !== null)
                                formData.append('avatar', file)
                            formData.append('user', JSON.stringify(operatorState.createOperatorRequest))
                            dispatch(createOperator(formData))
                            dispatch(setOpenNewOperatorModal(false))
                        }}
                        color={"blue"}
                        variant={"solid"}
                        size={"sm"}
                        iconPosition="off"
                        fullWidth
                        disabled={
                            operatorState.createOperatorRequest.name === "" ||
                            operatorState.createOperatorRequest.surname === "" ||
                            operatorState.createOperatorRequest.email === ""
                        }
                    />
                </div>
            </div>
        </ModalComponent >
    )
}