import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../../lib/utilis/index.tsx";
import { AcubeAccountDTO, AcubeReconnectResponseDTO } from "./dto.ts";
import { NewBankService } from "./service.ts";

interface BankState {
    findAcubeConnectRequestStatus: PromiseStatuses
    findByIdAcubeGetAccountStatus: PromiseStatuses
    findByIdAcubeGetAccountResponse: AcubeAccountDTO[],
    acubeReconnectRequestStatus: PromiseStatuses,
    acubeReconnectRequestResponse?: AcubeReconnectResponseDTO
    acubeGetTransactionsResponse: string
    acubeGetTransactionsStatus: PromiseStatuses
    openDownloadPdfAcubeAccountsModal: boolean
}

const initialState: BankState = {
    findAcubeConnectRequestStatus: 'idle',
    findByIdAcubeGetAccountStatus: 'idle',
    findByIdAcubeGetAccountResponse: [],
    acubeReconnectRequestStatus: 'idle',
    acubeGetTransactionsResponse: '',
    acubeGetTransactionsStatus: 'idle',
    openDownloadPdfAcubeAccountsModal: false
}

export const findAcubeConnectRequest = createAsyncThunk(
    'bank/findAcubeConnectRequest',
    async (id: string): Promise<string> => {
        const bankService = NewBankService()

        return bankService.findAcubeConnectRequest(id)
    }
)

export const findByIdAcubeGetAccount = createAsyncThunk(
    'bank/findByIdAcubeGetAccount',
    async (request: { id: string, uuid: string[] }): Promise<AcubeAccountDTO[]> => {
        let promises: Promise<AcubeAccountDTO>[] = []
        promises = request.uuid.map(async (id) => {
            const bankService = NewBankService()

            return bankService.findByIdAcubeGetAccount(request.id, id)
        })
        return Promise.all(promises)
    }
)

export const acubeReconnectRequest = createAsyncThunk(
    'bank/acubeReconnectRequest',
    async (request: { id: string, uuid: string }, thunkApi): Promise<AcubeReconnectResponseDTO> => {
        const bankService = NewBankService()

        return bankService.acubeReconnectRequest(request.id, request.uuid)
    }
)

export const acubeGetTransactions = createAsyncThunk(
    'bank/acubeGetTransactions',
    async (request: { fiscalId: string, uuid: string, madeOnAfter: string, madeOnBefore: string }, thunkApi): Promise<string> => {
        const bankService = NewBankService()

        return bankService.acubeGetTransaction(request.fiscalId, request.uuid, request.madeOnAfter, request.madeOnBefore)
    }
)

const bankSlice = createSlice({
    name: 'bank/slice',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(findAcubeConnectRequest.pending, (state) => {
                state.findAcubeConnectRequestStatus = 'loading'
            })
            .addCase(findAcubeConnectRequest.fulfilled, (state, action) => {
                state.findAcubeConnectRequestStatus = 'successfully'
            })
            .addCase(findAcubeConnectRequest.rejected, (state) => {
                state.findAcubeConnectRequestStatus = 'failed'
            })
            .addCase(findByIdAcubeGetAccount.pending, (state) => {
                state.findByIdAcubeGetAccountStatus = 'loading'
            })
            .addCase(findByIdAcubeGetAccount.fulfilled, (state, action) => {
                state.findByIdAcubeGetAccountStatus = 'successfully'
                state.findByIdAcubeGetAccountResponse = action.payload
            })
            .addCase(findByIdAcubeGetAccount.rejected, (state) => {
                state.findByIdAcubeGetAccountStatus = 'failed'
            })
            .addCase(acubeReconnectRequest.pending, (state) => {
                state.acubeReconnectRequestStatus = 'loading'
            })
            .addCase(acubeReconnectRequest.fulfilled, (state, action) => {
                state.acubeReconnectRequestStatus = 'successfully'
                state.acubeReconnectRequestResponse = action.payload
            })
            .addCase(acubeReconnectRequest.rejected, (state) => {
                state.acubeReconnectRequestStatus = 'failed'
            })
            .addCase(acubeGetTransactions.pending, (state) => {
                state.acubeGetTransactionsStatus = 'loading'
            })
            .addCase(acubeGetTransactions.fulfilled, (state, action) => {
                state.acubeGetTransactionsStatus = 'successfully'
                state.acubeGetTransactionsResponse = action.payload
            })
            .addCase(acubeGetTransactions.rejected, (state) => {
                state.acubeGetTransactionsStatus = 'failed'
            })
    },
    reducers: {
        setFindAcubeConnectRequestStatus: (state, action) => {
            state.findAcubeConnectRequestStatus = action.payload
        },
        setAcubeReconnectRequestStatus: (state, action) => {
            state.acubeReconnectRequestStatus = action.payload
        },
        setAcubeGetTransactionsStatus: (state, action) => {
            state.acubeGetTransactionsStatus = action.payload
        },
        setOpenDownloadPdfAcubeAccountsModal: (state, action) => {
            state.openDownloadPdfAcubeAccountsModal = action.payload
        },
    },
})

export const {
    setFindAcubeConnectRequestStatus,
    setAcubeReconnectRequestStatus,
    setAcubeGetTransactionsStatus,
    setOpenDownloadPdfAcubeAccountsModal
} = bankSlice.actions

export default bankSlice.reducer

