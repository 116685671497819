import React from "react";
import { useAppSelector } from "../../../lib/redux/hook.ts";
import { ProgressBarColor, ProgressBarColorMap } from './dto.ts';

interface Props {
    value: number
    color: ProgressBarColor
}

export function ProgressBar(props: Props) {
    const { value } = props
    const { color } = props

    const layoutState = useAppSelector(state => state.layout)

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{
                height: '4px',
                backgroundColor: layoutState.theme[200],
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                borderRadius: '16px'
            }}>
                <div
                    style={{
                        height: '4px',
                        backgroundColor: ProgressBarColorMap(color),
                        width: value + '%',
                        borderRadius: '16px',
                        transition: 'width ease-in-out 1s'
                    }}
                />
            </div >
            <span style={{ marginLeft: '12px' }} className='text-label-sm font-medium text-neutral-400'>
                {value.toFixed(0)}%
            </span>
        </div>
    )
}