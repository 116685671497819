import React, { useState } from "react"
import { useAppSelector } from "../../../lib/redux/hook.ts"
import { colors } from "../../../ui/colors.ts"
import { ArrowRightIcon } from "../../../ui/icons/arrowRightIcon.tsx"
import { CompanyIcon } from "../../../ui/icons/companyIcon.tsx"
import { ButtonComponent } from "../../../ui/molecules/button/index.tsx"
import { InputComponent } from "../../../ui/molecules/input/index.tsx"
import { MessageComponent } from "./message.tsx"

export function ChatComponent() {
    const layoutState = useAppSelector(state => state.layout)

    const [empty, setEmpty] = useState(false)

    return (
        <div style={{
            height: '100%',
            backgroundColor: colors.white,
            width: '100%',
            borderRadius: 12,
            border: '1px solid ' + colors.neutral[200],
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        }}>
            {
                empty ? (
                    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='text text--md typography--regular typography-neutral--700'>
                        Seleziona una chat per mandare messaggi.
                    </div>
                ) : (
                    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div style={{ padding: '16px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className="flex flex-row items-center gap-3 flex-grow">
                                <div className="w-[48px] h-[48px] rounded-[240px] flex justify-center items-center" style={{ backgroundColor: layoutState.theme[500] }}>
                                    <CompanyIcon size={"24"} color={layoutState.theme[50]} />
                                </div>
                                <span className="text-text-md font-bold text-neutral-800">
                                    Chat Aziendale
                                </span>
                            </div>
                        </div>
                        <div style={{ backgroundColor: colors.brandSecondary[200], height: 'calc(100% - 147.98px)' }}>
                            <MessageComponent
                                message={"messaggio di prova"}
                                sent={true}
                                date={new Date()}
                            />
                        </div>
                        <div style={{ padding: '16px', gap: 8, display: 'flex', flexDirection: 'row', alignItems: 'center', height: '72px' }}>
                            <InputComponent
                                value={""}
                                onChangeText={() => undefined}
                                id={'chat'}
                                type={'text'}
                                placeholder={'Inserisci un messaggio'}
                            />
                            <ButtonComponent
                                label={''}
                                onClick={() => undefined}
                                color={"blue"}
                                variant={"solid"}
                                size={"md"}
                                iconPosition={"only"}
                                icon={<ArrowRightIcon size={"20"} color={colors.white} />}
                            />
                        </div>
                    </div>
                )
            }
        </div>
    )
}