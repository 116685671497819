import React, { useState } from "react"
import { useAppSelector } from "../../../lib/redux/hook.ts"
import { CompanyIcon } from "../../../ui/icons/companyIcon.tsx"
import { SearchIcon } from "../../../ui/icons/searchIcon.tsx"
import { Badges } from "../../../ui/molecules/badge/index.tsx"
import { InputComponent } from "../../../ui/molecules/input/index.tsx"

export function UsersList() {
    const layoutState = useAppSelector(state => state.layout)

    const [isHover, setIsHover] = useState(false)
    return (
        <div className="bg-white w-[40%] py-6 px-4 rounded-xl border border-rgba(225, 227, 234, 1) overflow-auto">
            <div className="flex flex-col w-full gap-4 flex-grow">
                <InputComponent
                    id={'chat-find-user'}
                    type={'text'}
                    onChangeText={() => undefined}
                    placeholder={'Cerca'}
                    startIcon={<SearchIcon />}
                />
                <div style={{ overflow: 'auto', height: 'calc(100% - 50px)' }}>
                    <div
                        key={""}
                        style={{ borderColor: layoutState.theme[200] }}
                        className="cursor-pointer flex flex-row items-center justify-between w-full gap-3 p-2 border-b hover:bg-brandPrimary-100"
                    >
                        <div className="flex flex-row items-center gap-3 flex-grow">
                            <div className="w-[48px] h-[48px] rounded-[240px] flex justify-center items-center" style={{ backgroundColor: layoutState.theme[500] }}>
                                <CompanyIcon size={"24"} color={layoutState.theme[50]} />
                            </div>
                            <div className="flex flex-col">
                                <span className="text-text-md font-bold text-neutral-800">
                                    Chat Aziendale
                                </span>
                                <span className="text-text-sm font-normal text-neutral-500">
                                    Lorem Ipsum è un testo segnaposto
                                </span>
                            </div>
                        </div>
                        <div className="flex flex-col items-end gap-[6px]">
                            <div className='text-label-xs text-neutral-500'>
                                10.00
                            </div>
                            <div className="w-auto">
                                <Badges size={"xs"} label={"1"} outline={false} emphasis={true} color={"red"} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}