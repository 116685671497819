import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../../lib/utilis/index.ts";
import { ChatDTO } from "./dto.ts";
import { NewChatService } from "./service.ts";

interface ChatsState {
    findChatsStatus: PromiseStatuses
    findChatResponse: ChatDTO[]
}

const initialState: ChatsState = {
    findChatsStatus: 'idle',
    findChatResponse: [],
}

export const findChats = createAsyncThunk(
    'chat/findChats',
    async (topic: string): Promise<ChatDTO[]> => {
        const chatsService = NewChatService()

        return chatsService.findChats(topic)
    },
)

const chatSlice = createSlice({
    name: 'chat/slice',
    initialState,
    reducers: {

    },
    extraReducers(builder) {
        builder
            .addCase(findChats.pending, (state) => {
                state.findChatsStatus = 'loading'
            })
            .addCase(findChats.fulfilled, (state, action) => {
                state.findChatsStatus = 'successfully'
                state.findChatResponse = action.payload
            })
            .addCase(findChats.rejected, (state) => {
                state.findChatsStatus = 'failed'
            })
    },
})

export const {

} = chatSlice.actions

export default chatSlice.reducer
