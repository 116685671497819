import { ChatDTO } from "./dto.ts";
import { ChatServiceImpl } from "./serviceImpl.ts";


export interface ChatService {
    findChats(topic: string): Promise<ChatDTO[]>
}

export function NewChatService(): ChatService {
    return new ChatServiceImpl();
}