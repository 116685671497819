import clsx from "clsx";
import { addDays, addMonths, endOfMonth, endOfWeek, format, isSameDay, isSameMonth, lastDayOfMonth, startOfMonth, startOfWeek, subMonths } from 'date-fns';
import { it } from 'date-fns/locale/it';
import React, { ReactElement, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hook.ts";
import { capitalize } from "../../../lib/utilis/index.ts";
import { setCurrentMonth, setFromDateFilter, setToDateFilter } from "../../../pages/company/events/slice.ts";
import { colors } from "../../colors.ts";
import { ChevronIcon } from "../../icons/pagesIcon.tsx";
import './Datepicker.css';
import { dayMap } from './Datepicker.types.ts';


interface Props {
    onClick: (date) => void
}
export function Datepicker(props: Props) {
    const { onClick } = props

    const eventsState = useAppSelector(state => state.events)
    const layoutState = useAppSelector(state => state.layout)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (eventsState.findAllEventsStatus === "successfully" && eventsState.findAllEventsResponse !== undefined) {
            const eventDates = eventsState.findAllEventsResponse?.data.map(event => new Date(event.date));
            setDates(eventDates || []);
        }
    }, [eventsState.findAllEventsResponse, eventsState.findAllEventsStatus]);

    const [dates, setDates] = useState<Date[]>([new Date()])

    const nextMonth = () => {
        const month = addMonths(eventsState.currentMonth, 1)
        dispatch(setCurrentMonth(month));
        dispatch(setFromDateFilter(format(new Date(month.getFullYear(), month.getMonth(), 1), 'yyyy-MM-dd')))
        dispatch(setToDateFilter(format(lastDayOfMonth(new Date(month.getFullYear(), month.getMonth(), 1)), 'yyyy-MM-dd')))
    };

    const prevMonth = () => {
        const month = subMonths(eventsState.currentMonth, 1)
        dispatch(setCurrentMonth(month));
        dispatch(setFromDateFilter(format(new Date(month.getFullYear(), month.getMonth(), 1), 'yyyy-MM-dd')))
        dispatch(setToDateFilter(format(lastDayOfMonth(new Date(month.getFullYear(), month.getMonth(), 1)), 'yyyy-MM-dd')))
    };

    const renderHeader = () => {
        return (
            <div style={{
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: "space-between",
                padding: '0px 0px 0px 12px',
                width: "100%"
            }}
            >
                <span className='header-label text-neutral-700'>{capitalize(format(eventsState.currentMonth, 'MMMM yyyy', { locale: it }))}</span>
                <div className={"flex flex-row gap-4"}>
                    <ChevronIcon size={"24"} color={colors.neutral[400]} direction="l" cursor={'pointer'} onClick={prevMonth} />
                    <ChevronIcon size={"24"} color={colors.neutral[400]} direction="r" cursor={'pointer'} onClick={nextMonth} />
                </div>
            </div>
        );
    };

    const renderDays = () => {
        const days: ReactElement[] = [];
        const date = startOfWeek(new Date(), { weekStartsOn: 1 });

        for (let i = 0; i < 7; i++) {
            days.push(
                <div
                    className={
                        clsx("day-label", {
                            "text-red-500": i === 6
                        })
                    }
                    key={i}
                >
                    {dayMap.get(i)}
                </div>
            );
        }

        return <div className="days">{days}</div>;
    };

    const renderCells = () => {
        const monthStart = startOfMonth(eventsState.currentMonth);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart, { weekStartsOn: 1 });
        const endDate = endOfWeek(monthEnd, { weekStartsOn: 1 });

        const rows: ReactElement[] = [];
        let days: ReactElement[] = [];
        let day = startDate;
        let formattedDate = '';

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, 'd');
                const cloneDay = day;
                const isHighlighted = dates.some(date => isSameDay(date, day));
                days.push(
                    <div
                        className={`cell ${!isSameMonth(day, monthStart)
                            ? 'disabled'
                            : !isHighlighted && isSameDay(day, new Date()) ? 'selected' : isHighlighted ? 'array'
                                : ''
                            } `}
                        key={day.toString()}
                        onClick={() => onClick(cloneDay)}
                    >
                        <span
                            style={{
                                backgroundColor: isHighlighted ? layoutState.theme[500] : '',
                                borderColor: isHighlighted || (!isHighlighted && isSameDay(day, new Date())) ? layoutState.theme[500] : '',
                                borderWidth: "1px solid"
                            }}
                            className={
                                clsx("number", {
                                    "text-red-500": i === 6
                                })
                            }
                        >
                            {formattedDate}
                        </span>
                    </div>
                );
                day = addDays(day, 1);
            }
            rows.push(
                <div className="days" key={day.toString()}>
                    {days}
                </div>
            );
            days = [];
        }
        return <div className='flex gap-4 flex-col'>{rows}</div>;
    };

    return (
        <div className="calendar">
            <div className={"flex gap-2 flex-col"}>
                {renderHeader()}
                <div className={"flex gap-2 flex-col"}>
                    {renderDays()}
                    {renderCells()}
                </div>
            </div>
        </div>
    );
};

export default Datepicker;
